<template>
        <v-row no-gutters class="justify-center pa-5">
            <div class="btns" v-if="($route.params.id || $route.name == 'SegmentationForm') && !list">
                <v-btn @click="$router.go(-1)" outlined class="mr-3" color="white">{{$t('cancelLabel')}}</v-btn>
                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn outlined color="white" v-bind="attrs" v-on="on">
                            {{$t('action')}}
                            <v-icon right dark>$dropdown</v-icon>
                        </v-btn>
                    </template>
                    <v-list dense>
                        <v-list-item @click="showSaveModal" link>
                            <v-list-item-title>{{$t('saveSegmentation')}}</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="$func.hasRight('export/export')" @click="exportData" link>
                            <v-list-item-title>{{$t('exportData')}}</v-list-item-title>
                        </v-list-item>
                        <!-- À décommenter quand on fera le dev -->
                        <!-- <v-list-item link>
                            <v-list-item-title>{{$t('assignTheseContactsTo')}}</v-list-item-title>
                        </v-list-item>
                        <v-list-item link>
                            <v-list-item-title>{{$t('defineAutomaticAssignmentRule')}}</v-list-item-title>
                        </v-list-item>
                        <v-list-item link>
                            <v-list-item-title>{{$t('sendSMS')}}</v-list-item-title>
                        </v-list-item>
                        <v-list-item link>
                            <v-list-item-title>{{$t('sendMMS')}}</v-list-item-title>
                        </v-list-item>
                        <v-list-item link>
                            <v-list-item-title>{{$t('sendEMAIL')}}</v-list-item-title>
                        </v-list-item>
                        <v-list-item link>
                            <v-list-item-title>{{$t('sendMAIL')}}</v-list-item-title>
                        </v-list-item>
                        <v-list-item link>
                            <v-list-item-title>{{$t('deleteTheseContacts')}}</v-list-item-title>
                        </v-list-item> -->
                    </v-list>
                </v-menu>
            </div>
            <div class="btns" v-else>
                <v-btn outlined depressed small class="mr-2" v-if="!list" color="white" @click="$emit('closeSegmentModal')">{{$t('cancelLabel')}}</v-btn>
                <v-btn outlined depressed small class="mr-2" v-else color="white" @click="closeListConfig">{{$t('cancelLabel')}}</v-btn>
                <v-btn outlined depressed small color="white" v-if="!list" @click="showSaveModal">{{$t('recordSegment')}}</v-btn>
                <v-btn outlined depressed small color="white" v-else @click="saveListConfig">{{$t('recordListConfig')}}</v-btn>
            </div>
            <v-col cols="9" xl="7" v-if="data && schema && references" class="segmentation pa-3">
                <v-row class="pb-3">
                    <v-col cols="4">
                        <v-btn dense depressed color="primary" @click="openSegmentDialog = true">{{$t('useExistingSegmentation')}}</v-btn>
                        <v-dialog width="500" v-model="openSegmentDialog">
                            <v-card>
                                <v-toolbar tile flat dark  :style="'background-image:url('+publicPath+'img/blue-background.jpg); background-size:cover;'">
                                    <v-toolbar-title>{{$t('choiceSegment')}}</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <v-btn outlined depressed small class="mr-2" @click="openSegmentDialog = false">{{$t('cancelLabel')}}</v-btn>
                                    <v-btn outlined depressed small @click="loadSegment">{{$t('confirmLabel')}}</v-btn>
                                </v-toolbar>
                                <v-card-text class="pt-6">
                                    <v-select ref="segment" :items="formSegments" :label="$t('segment') + ' *'" filled dense v-model="segmentSegment" :no-data-text="$t('no-data-text')"></v-select>
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                    </v-col>
                </v-row>
                <div v-for="(or, orIndex) in data" :key="'or_'+orIndex" class="or">
                    <v-chip small class="lightgrey operatorChip">
                        <span class="text-uppercase" v-if="orIndex == 0">{{$t('if')}}</span>
                        <span class="text-uppercase" v-else>{{$t('or')}}</span>
                    </v-chip>
                    <div class="divider">
                        <v-divider class="my-3"></v-divider>
                    </div>
                    <div v-for="(and, andIndex) in or" :key="'or_'+orIndex+'and_'+andIndex" v-add-class-hover="'active'" class="andDiv my-3 input-form">
                        <v-btn small text class="square removeBtn" @click="removeAnd(orIndex, andIndex)">
                            <v-icon small>
                                $minus_circle_l
                            </v-icon>
                        </v-btn>
                        <v-autocomplete
                            solo flat
                            hide-details
                            dense
                            :label="$t('field')"
                            @change="resetValues(orIndex, andIndex)"
                            v-model="data[orIndex][andIndex].field"
                            :items="firstAutocomplete"
                            item-value="value"
                            item-text="name"
                            class="field mx-3"
                            :no-data-text="$t('no-data-text')"
                        >
                        </v-autocomplete>

                        <v-select
                            solo flat
                            hide-details
                            dense
                            :label="$t('operator')"
                            @change="changeOperator(orIndex, andIndex)"
                            :disabled="data[orIndex][andIndex].field == ''"
                            v-model="data[orIndex][andIndex].operator"
                            :items="schema[data[orIndex][andIndex].field]"
                            item-text="value"
                            item-value="value"
                            return-object
                            class="field mx-3"
                            :no-data-text="$t('no-data-text')"
                        >
                            <template slot="selection" slot-scope="data">
                                {{ $t(data.item.operatorLabel ? data.item.operatorLabel : data.item.value).toLowerCase() }}
                            </template>
                            <template slot="item" slot-scope="data">
                                {{ $t(data.item.operatorLabel ? data.item.operatorLabel : data.item.value).toLowerCase() }}
                            </template>
                        </v-select>

                        <v-text-field
                            :label="$t('value')"
                            solo flat
                            class="field mx-3"
                            hide-details
                            dense
                            disabled
                            v-if="data[orIndex][andIndex].field == '' || (data[orIndex][andIndex].operator == '' || Object.keys(data[orIndex][andIndex].operator).length == 0)"
                        ></v-text-field>
                        <div v-else class="field mx-3">
                            <v-select
                                solo flat
                                hide-details
                                dense
                                :label="$t(data[orIndex][andIndex].operator.label)"
                                @change="getDetailledStats"
                                :disabled="data[orIndex][andIndex].operator == ''"
                                v-model="data[orIndex][andIndex].value"
                                :multiple="references[data[orIndex][andIndex].operator.ref].multiple"
                                v-if="data[orIndex][andIndex].field != '' && data[orIndex][andIndex].operator != '' && references[data[orIndex][andIndex].operator.ref] && references[data[orIndex][andIndex].operator.ref].type == 'select'"
                                :items="references[data[orIndex][andIndex].operator.ref].content"
                                item-text="text"
                                item-value="value"
                                :no-data-text="$t('no-data-text')"
                            >
                            </v-select>
                            <v-autocomplete
                                solo flat
                                hide-details
                                dense
                                :label="$t(data[orIndex][andIndex].operator.label)"
                                :disabled="data[orIndex][andIndex].operator == ''"
                                v-model="data[orIndex][andIndex].value"
                                :multiple="references[data[orIndex][andIndex].operator.ref].multiple"
                                v-if="data[orIndex][andIndex].field != '' && data[orIndex][andIndex].operator != '' && references[data[orIndex][andIndex].operator.ref] && references[data[orIndex][andIndex].operator.ref].type == 'autocomplete'"
                                :items="references[data[orIndex][andIndex].operator.ref].content"
                                item-text="text"
                                :item-value="data[orIndex][andIndex].category.includes('contactItems') ? 'text' : 'value'"
                                :no-data-text="$t('no-data-text')"
                            >
                            </v-autocomplete>
                            <v-combobox
                                solo flat
                                hide-details
                                dense
                                :label="$t(data[orIndex][andIndex].operator.label)"
                                @focus="loadComboboxEntries(data[orIndex][andIndex].value, data[orIndex][andIndex].field)"
                                @update:search-input="loadComboboxEntries($event, data[orIndex][andIndex].field)"
                                :disabled="data[orIndex][andIndex].operator == ''"
                                v-model="data[orIndex][andIndex].value"
                                :multiple="references[data[orIndex][andIndex].operator.ref].multiple"
                                v-if="data[orIndex][andIndex].field != '' && data[orIndex][andIndex].operator != '' && references[data[orIndex][andIndex].operator.ref] && references[data[orIndex][andIndex].operator.ref].type == 'combobox'"
                                :items="references[data[orIndex][andIndex].operator.ref].content"
                                item-text="text"
                                :item-value="data[orIndex][andIndex].category.includes('contactItems') ? 'text' : 'value'"
                                :no-data-text="$t('no-data-text')"
                            >
                            </v-combobox>
                            <v-text-field
                                solo flat
                                hide-details
                                dense
                                type="number"
                                min="0"
                                @keydown="validateNumber"
                                :label="$t(data[orIndex][andIndex].operator.label)"
                                required
                                :disabled="data[orIndex][andIndex].operator == ''"
                                v-model="data[orIndex][andIndex].value"
                                v-if="data[orIndex][andIndex].field != '' && data[orIndex][andIndex].operator != '' && references[data[orIndex][andIndex].operator.ref] && references[data[orIndex][andIndex].operator.ref].type == 'number'"
                            >
                            </v-text-field>
                            <v-text-field
                                solo flat
                                hide-details
                                dense
                                type="text"
                                :label="$t(data[orIndex][andIndex].operator.label)"
                                required
                                :disabled="data[orIndex][andIndex].operator == ''"
                                v-model="data[orIndex][andIndex].value"
                                v-if="data[orIndex][andIndex].field != '' && data[orIndex][andIndex].operator != '' && references[data[orIndex][andIndex].operator.ref] && references[data[orIndex][andIndex].operator.ref].type == 'text'"
                            ></v-text-field>
                            <v-menu
                              v-model="data[orIndex][andIndex].menu"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="auto"
                              v-if="data[orIndex][andIndex].field != '' && data[orIndex][andIndex].operator != '' && references[data[orIndex][andIndex].operator.ref] && references[data[orIndex][andIndex].operator.ref].type == 'date'">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        solo flat
                                        hide-details
                                        dense
                                        @input="getDetailledStats"
                                        :label="$t(data[orIndex][andIndex].operator.label)"
                                        required
                                        readonly
                                        :disabled="data[orIndex][andIndex].operator == ''"
                                        v-model="data[orIndex][andIndex].value"
                                        append-icon="$calendar_alt_l"
                                        v-bind="attrs"
                                        v-on="on"
                                        >
                                    </v-text-field>
                                </template>
                                <v-date-picker v-model="data[orIndex][andIndex].value" no-title :first-day-of-week="1" :locale="$i18n.locale" @input="data[orIndex][andIndex].menu = false"></v-date-picker>
                            </v-menu>
                        </div>
                        
                        <v-chip v-if="andIndex < (or.length - 1)" small class="lightgrey operatorChip text-uppercase">
                            {{$t('and')}}
                        </v-chip>
                        <div v-else class="blank"></div>
                    </div>
                    <div class="addButton">
                        <div class="blank"></div>
                        <div class="field mx-3">
                            <v-btn depressed class="square" color="primary" @click="addAnd(orIndex)">
                                <v-icon>$plus_l</v-icon>
                            </v-btn>
                        </div>
                        <div class="field mx-3"></div>
                        <div class="field mx-3"></div>
                        <div class="blank"></div>
                    </div>
                </div>
                <div class="or">
                    <v-chip small class="lightgrey operatorChip">
                        <span class="text-uppercase" v-if="data.length > 0">{{$t('or')}}</span>
                        <span class="text-uppercase" v-else>{{$t('if')}}</span>
                    </v-chip>
                    <div class="divider">
                        <v-divider class="my-3"></v-divider>
                    </div>
                    <div class="addButton my-3">
                        <v-btn depressed class="square  mx-3" color="primary" @click="addOr">
                            <v-icon>$plus_l</v-icon>
                        </v-btn>
                    </div>
                </div>
            </v-col>
            <v-col cols="3" class="pa-3 px-6">
                <v-card color="primary" rounded="lg">
                    <div v-if="realTime && Object.keys(realTime).length > 0" class="white--text realTimeNumbers">
                        <span class="number">{{realTime.number}}</span>
                        <span class="text-uppercase"><b>{{$t('contacts')}}</b>/{{totalNumber}}</span>
                    </div>
                    <div class="white--text realTimeNumbers" v-else>
                        {{$t('noRulesDefined')}}
                    </div>
                </v-card>
                <div v-if="realTime && Object.keys(realTime).length > 0">
                    <div v-for="(value, index) in realTime.detail" :key="'detail_'+index" class="mt-4">
                        <div class="headingDetail">
                            <span class="primary--text">{{$t(value.label)}}</span>
                            <span class="primary--text">{{value.value}}/{{realTime.number}}</span>
                        </div>
                        <v-progress-linear :value="getValue(value.value, realTime.number)"></v-progress-linear>
                    </div>
                    <div style="height: 300px;" key="detail_entities" class="mt-4" v-if="realTime.aggregations.entities.length != 0">
                        <div class="headingDetail">
                            <span class="primary--text">{{$t('repartitionByEntity')}} :</span>
                        </div>
                        <div style="height:80%; display:flex; align-items:center; justify-content:space-between;">
                            <DonutComponent :key="donutKey" :dataSet="graphData" :legend="false" class="px-4" style="height:100%; width:50%;"/>
                            <div class="legend mr-6">
                                <div v-for="(legend, index) in labels('entities')" :key="'legend_' + index">
                                    <span :style="'width: 20px; height:10px; display:inline-block; background-color:' + backgroundRefs[index]"></span> {{legend}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </v-col> 
            <v-dialog v-model="recordNotPossible" max-width="500px" persistent>
                <v-card>
                    <v-card-title>
                        <span>{{$t("ErrorRecordSegmentationTitle")}}</span>
                        <v-spacer></v-spacer>
                    </v-card-title>
                    <v-card-text>
                        {{$t("ErrorRecordSegmentationText")}}
                    </v-card-text>
                    <v-card-actions class="justify-end">
                        <v-btn color="error" depressed @click="recordNotPossible = false">
                            {{$t("closeModalLabel")}}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogOverwrite" max-width="500" persistent>
                <v-card>
                    <v-toolbar dark color="grey lighten-3" dense flat>
                        <v-toolbar-title class="text-body-2 font-weight-bold grey--text">{{$t('saveSegmentation')}}</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text class="doubleValidation pt-4">
                        <span>{{$t('doYouWantOverrideExistingSegmentation')}} ?</span>
                        <v-radio-group row hide-details v-model="overwriteSegment">
                            <v-radio :label="$t('yes')" :value="true"></v-radio>
                            <v-radio :label="$t('no')" :value="false"></v-radio>
                        </v-radio-group>
                    </v-card-text>
                    <v-card-actions class="pt-3">
                        <v-spacer></v-spacer>
                        <v-btn color="grey" text class="body-2 font-weight-bold" @click.native="cancel">{{$t('confirmationCancel')}}</v-btn>
                        <v-btn color="primary" class="body-2 font-weight-bold" outlined @click.native="saveOverwriteChoice">{{$t('confirmationConfirm')}}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogSave" max-width="500" persistent>
                <v-card>
                    <v-toolbar dark color="grey lighten-3" dense flat>
                        <v-toolbar-title class="text-body-2 font-weight-bold grey--text">{{$t('save')}}</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text class="doubleValidation pt-4">
                        <p class="error--text"><v-icon color="error" class="mr-3">$warning_l</v-icon>{{$t('segmentCurrentlyUsedOn')}} :</p>
                        <ul class="error--text mb-4">
                            <li v-for='(impact, index) in this.updateImpact' :key="index">{{impact.nb}} {{$t(impact.label)}}</li>
                        </ul>
                        <span>{{$t('wouldYouLikePropagateYourChanges')}} ?</span>
                        <v-radio-group row hide-details v-model="updatePropagation">
                            <v-radio :label="$t('yes')" :value="true"></v-radio>
                            <v-radio :label="$t('no')" :value="false"></v-radio>
                        </v-radio-group>
                    </v-card-text>
                    <v-card-actions class="pt-3">
                        <v-spacer></v-spacer>
                        <v-btn color="grey" text class="body-2 font-weight-bold" @click.native="cancel">{{$t('confirmationCancel')}}</v-btn>
                        <v-btn color="primary" class="body-2 font-weight-bold" outlined @click.native="agree">{{$t('confirmationConfirm')}}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogSaveAs" max-width="500" persistent>
                <v-card>
                    <v-toolbar dark color="grey lighten-3" dense flat>
                        <v-toolbar-title class="text-body-2 font-weight-bold grey--text">{{$t('saveAs')}}</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text class="doubleValidation pt-4">
                        <div class="pb-4">
                            <span>{{$t('newSegmentName')}} *</span>
                            <v-text-field class="mt-2" filled dense hide-details v-model="name"></v-text-field>
                        </div>
                        <div>
                            <span>{{$t('owner_entity')}} *</span>
                            <v-select :items="entities" v-model="entity_id" dense filled hide-details class="mt-2" :no-data-text="$t('no-data-text')"></v-select>
                        </div>
                    </v-card-text>
                    <v-card-actions class="pt-3">
                        <v-spacer></v-spacer>
                        <v-btn color="grey" text class="body-2 font-weight-bold" @click.native="cancel">{{$t('confirmationCancel')}}</v-btn>
                        <v-btn color="primary" class="body-2 font-weight-bold" outlined @click.native="agree">{{$t('confirmationConfirm')}}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <FormDialogComponent v-if="form" ref="componentForm" :form="form" :action="'SET_DATA'" />
            <ActionMenusDialogComponent v-if="showActionMenusDialog" :items="itemsActionMenusDialog" ref="actionMenus" @actionForm="actionForm" @cancel="showActionMenusDialog = false;" />
        </v-row>
</template>

<script>
import GenericDataService from '@/services/GenericDataService'
import DonutComponent from '@/components/graphs/DonutComponent'
import getForm from '@/mixins/mixins';
import ActionMenusDialogComponent from '@/components/dialogs/ActionMenusDialogComponent';

export default {
    name:"SegmentationComponent",
    components:{ DonutComponent, ActionMenusDialogComponent },
    props:['list', 'openListEditor'],
    mixins:[getForm],
    data() {
        return {
            recordNotPossible: false,
            publicPath: process.env.BASE_URL,
            tempItems:[],
            segmentSegment: '0', 
            formSegments: [], 
            name: "",
            entity_id: "",
            tmpName: "",
            tmp_entity_id: "",
            default_entity_id: "",
            entities: [],
            data: [],
            schema: {},
            schemaTmp: {},
            references: {},
            firstAutocomplete: [],
            realTime: {},
            dialogSave: false,
            dialogSaveAs: false,
            updatePropagation: false,
            updateImpact: [],
            modifyLabel: false,
            openSegmentDialog: false,
            newSegmentId:0,
            dialogOverwrite:false,
            overwriteSegment:false,
            datePickerMenu:[],
            backgroundRefs: ["#FFEBCD","#0000FF","#8A2BE2","#A52A2A","#DEB887","#5F9EA0","#7FFF00","#D2691E","#FF7F50","#6495ED","#FFF8DC","#DC143C","#00FFFF","#00008B","#008B8B","#B8860B","#A9A9A9","#006400","#BDB76B","#8B008B","#556B2F","#FF8C00","#9932CC","#8B0000","#E9967A","#8FBC8F","#483D8B","#2F4F4F","#00CED1","#9400D3","#FF1493","#00BFFF","#696969","#1E90FF","#B22222","#FFFAF0","#228B22","#FF00FF","#DCDCDC","#F8F8FF","#FFD700","#DAA520","#ADFF2F","#F0FFF0","#FF69B4","#CD5C5C","#4B0082","#FFFFF0","#F0E68C","#E6E6FA","#FFF0F5","#7CFC00","#FFFACD","#ADD8E6","#F08080","#E0FFFF","#FAFAD2","#90EE90","#D3D3D3","#FFB6C1","#FFA07A","#20B2AA","#87CEFA","#778899","#B0C4DE","#FFFFE0","#00FF00","#32CD32","#FAF0E6","#FF00FF","#66CDAA","#0000CD","#BA55D3","#9370DB","#3CB371","#7B68EE","#00FA9A","#48D1CC","#C71585","#191970","#F5FFFA","#FFE4E1","#FFE4B5","#FFDEAD","#000080","#FDF5E6","#808000","#6B8E23","#FFA500","#FF4500","#DA70D6","#EEE8AA","#98FB98","#AFEEEE","#DB7093","#FFEFD5","#FFDAB9","#CD853F","#FFC0CB","#DDA0DD","#B0E0E6","#FF0000","#BC8F8F","#4169E1","#8B4513","#FA8072","#F4A460","#2E8B57","#FFF5EE","#A0522D","#C0C0C0","#87CEEB","#6A5ACD","#708090","#FFFAFA","#00FF7F","#4682B4","#D2B48C","#008080","#D8BFD8","#FF6347","#40E0D0","#EE82EE","#F5DEB3","#FFFFFF","#F5F5F5","#FFFF00","#9ACD32","#F0F8FF","#FAEBD7","#00FFFF","#7FFFD4","#F0FFFF","#F5F5DC","#FFE4C4","#000000"],
            donutKey:0,
            totalNumber: 0,
            form: "",
            showActionMenusDialog: false,
            itemsActionMenusDialog: [],
            loader: false,
            api:process.env.VUE_APP_API_URL,
        }
    },
    mounted() {
        this.initSelects();
        if(!this.list){
            this.segmentSegment = this.$route.params.id ? this.$route.params.id : '0';
            this.loadSegment();
        }
    },
    beforeCreate() {
        this.$options.components.FormDialogComponent = require('@/components/dialogs/FormDialogComponent').default
    },
    created() {
        this.segmentSegment = this.$route.params.id ? this.$route.params.id : '0';
        this.loadSegment();
    },
    watch: {
        openListEditor(val){
            if(val){
                this.segmentSegment = this.$route.params.id ? this.$route.params.id : '0';
                this.loadSegment();
            }
        },
        data: {
            handler(val){
                this.getDetailledStats(false)
            },
            deep:true
        },
        graphData(val){
            this.donutKey++
        }
    },
    computed: {
        graphData() {
            let graphData = {}
            if(this.realTime && this.realTime.aggregations && this.realTime.aggregations['entities']){
                graphData.datasets = [];
                let dataset = {};
                dataset.label = "";
                dataset.data = Object.values(this.realTime.aggregations['entities']).map(function (obj) { return obj.value });
                dataset.backgroundColor =  this.backgroundRefs;
                graphData.datasets.push(dataset);
                graphData.labels = this.labels('entities');
            }
            
            return graphData;
        },
    },
    methods: {
        saveListConfig(){
            let dataToRecord = this._.cloneDeep(this.data)
            this.$emit('saveListConfig', dataToRecord)
        },
        closeListConfig(){
            this.data = []
            this.$emit('closeSegmentModal');
        },
        changeLabel(){
            let segment = this.formSegments.find((e) => e.value == this.segmentSegment)
            if(!this.modifyLabel){
                this.modifyLabel = true,
                segment.text += " "+this.$t('modificationInProgressLabel')
            }
        },
        loadSegment() {
            this.modifyLabel = false
            if(this.segmentSegment !== '0'){
                GenericDataService.getData("/segment/"+this.segmentSegment+"/get").then((response) => {
                    this.$store.dispatch("segmentation/SET_SEGMENTATION_DATA", response.data.data);
                    this.data = response.data.data.data
                    this.name = response.data.data.name
                    this.entity_id = response.data.data.entity_id.id ? response.data.data.entity_id.id : response.data.data.entity_id
                    this.segmentSegment = response.data.data.id
                    this.initSchema();
                })
            } else {
                this.data = [];
                if(this.list && this.list.config){
                    this.data = this._.cloneDeep(this.list.config)
                }
                this.initSchema();
            }    
            this.initSelects();
            this.openSegmentDialog = false;
            GenericDataService.postData("/segment/try").then((response) => {
                this.totalNumber = response.data.data.totalNumber;
            });
        },
        changeOperator : function(orIndex, andIndex){
            if((this.data[orIndex][andIndex].value != '' && this.data[orIndex][andIndex].value.length > 0) || ['empty', 'notEmpty', 'valid', 'notValid', 'true', 'false', 'dateBeforeToday', 'dateAfterToday', 'dateEqualsToday'].includes(this.data[orIndex][andIndex].operator.value)){
                // this.getDetailledStats() ==> À voir si ça bug quelque chose mais au moins en commentant ça ne fait pas 2 fois l'appel
                this.changeLabel()
            }
        },
        resetValues : function(orIndex, andIndex){
            for(let cat in this.schemaTmp) {
                if(this.schemaTmp[cat].hasOwnProperty(this.data[orIndex][andIndex].field)) {
                    this.data[orIndex][andIndex].category = cat;
                    break;
                }
            }
            this.data[orIndex][andIndex].operator = {}
            this.data[orIndex][andIndex].value = ""
            this.changeLabel()
        },
        validateNumber(e) {
            if(['-', 'e', 'E', 'Dead', ',', '+', '.'].includes(e.key))
                e.preventDefault();
        },
        getDetailledStats: function(changeLabelParam = true){
            let dataToSend =  this._.cloneDeep(this.data)
            let filteredArray = [];
            dataToSend.forEach((orArray) => {
                let filteredOr = orArray.filter((e) => (e.value && e.value != "" && e.value.length != 0) || ['empty', 'notEmpty', 'valid', 'notValid', 'true', 'false', 'dateBeforeToday', 'dateAfterToday', 'dateEqualsToday'].includes(e.operator.value))
                if(filteredOr.length > 0){
                    filteredArray.push(filteredOr)
                }
            });
            if(filteredArray.length) {
                if(!this.list){
                    GenericDataService.postData("/segment/try?aggregations[]=entities", filteredArray).then((response) => {
                        this.realTime = response.data.data;
                    })
                } else {
                    let pool = this._.cloneDeep(this.list.default_pool)
                    pool.push(filteredArray)
                    GenericDataService.postData("/segment/try?postDataIsPool=true&aggregations[]=entities", pool).then((response) => {
                        this.realTime = response.data.data;
                    })
                }
                
            } else {
                this.realTime = {};
            }
            if(changeLabelParam){
                this.changeLabel()
            }
            //this.changeLabel()
        },
        recordSegment: function(){
            let dataToSend =  this._.cloneDeep(this.data)
            let filteredArray = [];
            dataToSend.forEach((orArray) => {
                if(orArray.length > 0 ){
                    let filteredOr = orArray.filter((e) => (e.value != "" && e.value.length != 0) || ['empty', 'notEmpty', 'valid', 'notValid', 'true', 'false', 'dateBeforeToday', 'dateAfterToday', 'dateEqualsToday'].includes(e.operator.value))
                    if(filteredOr.length > 0){
                        filteredArray.push(filteredOr)
                    }
                }
            });
            if(filteredArray.length == 0 || !this.segmentSegment || !this.name || !this.entity_id) {
                this.recordNotPossible = true;
                this.name = this.tmpName;
                this.entity_id = this.tmp_entity_id;
            } else {
                let payload = {
                    name: this.name,
                    entity_id: this.entity_id,
                    data: filteredArray
                }
                if(this.dialogSave && this.segmentSegment !== '0' && !this.updatePropagation) {
                    payload.name = this.name + " (copie)";
                } else if(this.segmentSegment !== '0' && (this.updatePropagation || this.overwriteSegment)) {
                    payload.id = this.segmentSegment;
                }
                this.loader = true
                GenericDataService.postData('/segment/set', payload).then((response) => {
                    if(this.$route.params.id  || this.$route.name == 'SegmentationForm'){
                        this.$store.dispatch('ADD_SNACK', {
                            message : this.$t('successActionConfirmed'),
                        }).then((e) => {
                            this.$store.dispatch("REMOVE_SNACK").then((e) => {
                                this.$router.push({name: "Segmentation"})
                            })
                        })
                    } else {
                        this.newSegmentId = response.data.data.id
                        this.$emit('recordedSegment', this.newSegmentId)
                    }
                    this.loader = false
                })
            }
        },
        initSelects: function() {
            GenericDataService.getData("/segment/_selectList").then((response) => {
                this.formSegments = [].concat.apply([{'value': '0', 'text': this.$t('newCustomSegment')}], response.data.data);
            });
        },
        async loadComboboxEntries(value, field) {
            let url = this.references['comboboxField'].url.replace('{{field}}', field).replace('{{value}}', value ? value : '');
            if(this.list && this.list.entity_id)
                url += "&entity_id=" + (this.list.entity_id.id ? this.list.entity_id.id : this.list.entity_id);
            await GenericDataService.getData(url).then((response) => {
                this.references['comboboxField'].content = response.data.data;
            });
        },
        initSchema: function(){
            GenericDataService.getData("/segment/getSchema").then( async (response) => {
                let schemaTmp = response.data.data.schema;
                let references = response.data.data.references;
                this.entities = response.data.data.entities
                if(!this.entity_id)
                    this.entity_id = response.data.data.defaultEntityId
                this.default_entity_id = response.data.data.defaultEntityId
                for (var property in references) {
                    if(references[property].url && references[property].url !== "" && references[property].type !== "combobox") {
                        if(references[property].url.includes('entityIds')){
                          let entity_id = this.list && this.list.entity_id ? this.list.entity_id : this.entity_id;
                            await GenericDataService.getData(references[property].url+"["+entity_id+"]").then((response) => {
                                let data = response.data.data;
                                if(this.list && this.list.id)
                                  data = data.filter(function(list) { return list.value !== this.list.id }, this);
                                references[property].content = data;
                            })
                        } else {
                            await GenericDataService.getData(references[property].url).then((response) => {
                                references[property].content = response.data.data
                            })
                        }
                    }
                    if(references[property].to_trad && references[property].content) {
                        for (var content in references[property].content) {
                            references[property].content[content].text = this.$t(references[property].content[content].text);
                        }
                    }
                }

                // Permet d'afficher les OptGroups sur le premier autocomplete
                this.firstAutocomplete = [];
                this.schema = {};
                for (var groupName in schemaTmp) {
                    this.firstAutocomplete.push({ header: this.$t('schema_' + groupName) });
                    for (var value in schemaTmp[groupName]) {
                        let name = value.includes('customUser') ? ('Perso : ' + value.split('.')[value.split('.').length - 1]) : this.$t('schema_' + groupName + '_' + value);
                        this.firstAutocomplete.push({ name: name, value: value, group: groupName });

                        for(var operator in schemaTmp[groupName][value]) {
                            if(schemaTmp[groupName][value][operator]['header'])
                                schemaTmp[groupName][value][operator]['header'] = this.$t(schemaTmp[groupName][value][operator]['header']);
                        }
                    }
                    this.schema = Object.assign(this.schema, schemaTmp[groupName]);
                }

                this.schemaTmp = schemaTmp;
                this.references = references;
            })
        },
        getValue: function(number, total){
            return number === 0 ? 0 : Math.round((number*100)/ total)
        },
        addOr: function(){
            let item = {
                field: "",
                operator: "",
                value:"",
                menu: 0
            }
            this.data.push([item]);
            this.changeLabel();
        },
        addAnd: function(orIndex){
            let item = {
                field: "",
                operator: "",
                value:"",
                menu: 0
            }
            this.data[orIndex].push(item);
            this.changeLabel();
        },
        removeAnd: function(orIndex, andIndex){
            let orElement = this.data[orIndex]
            orElement.splice(andIndex, 1);
            if(orElement.length == 0){
                this.data.splice(orIndex, 1)
            }
            this.changeLabel();
        },
        showSaveModal() {
            let dataToSend =  this._.cloneDeep(this.data)
            let filteredArray = [];
            dataToSend.forEach((orArray) => {
                if(orArray.length > 0 ){
                    let filteredOr = orArray.filter((e) => (e.value != "" && e.value.length != 0) || ['empty', 'notEmpty', 'valid', 'notValid', 'true', 'false', 'dateBeforeToday', 'dateAfterToday', 'dateEqualsToday'].includes(e.operator.value))
                    if(filteredOr.length > 0){
                        filteredArray.push(filteredOr)
                    }
                }
                
            });
            if(filteredArray.length == 0 || !this.segmentSegment) {
                this.recordNotPossible = true
            } else {
                if(this.segmentSegment !== '0') {
                    this.dialogOverwrite = true;
                } else {
                    this.tmpName = this.name;
                    this.tmp_entity_id = this.entity_id;
                    this.name = "";
                    this.entity_id = this.default_entity_id;
                    this.dialogSaveAs = true;  
                }
            }
        },
        saveOverwriteChoice() {
            this.dialogOverwrite = false;

            if(this.overwriteSegment) {
                // getBeforeUpdate
                GenericDataService.getData("/segment/checkBeforeUpdate?id="+this.segmentSegment).then((response) => {
                    if(response.data.data.impacts && response.data.data.impacts.length) {
                        this.updateImpact = response.data.data.impacts;
                        this.dialogSave = true;  
                    } else {
                        this.agree();
                    }
                });
            } else {
                this.tmpName = this.name;
                this.tmp_entity_id = this.entity_id;
                this.name = "";
                this.entity_id = this.default_entity_id;
                this.dialogSaveAs = true;
            }
        },
        agree() {
            this.recordSegment();
            this.dialogSave = false;
            this.dialogSaveAs = false;
        },
        cancel() {
            this.dialogSave = false;
            this.dialogSaveAs = false;
            this.dialogOverwrite = false;
            this.updatePropagation = false;
            this.overwriteSegment = false;
            this.name = this.tmpName;
            this.entity_id = this.tmp_entity_id;
        },
        labels(type) {
            return Object.values(this.realTime.aggregations[type]).map(function (obj) { return obj.to_trad ? this.$t(obj.label) : obj.label; }, this);
        },
        exportData: function() {
            let dataToSend =  this._.cloneDeep(this.data);
            let filteredArray = [];
            dataToSend.forEach((orArray) => {
                let filteredOr = orArray.filter((e) => (e.value && e.value != "" && e.value.length != 0) || ['empty', 'notEmpty', 'valid', 'notValid', 'true', 'false', 'dateBeforeToday', 'dateAfterToday', 'dateEqualsToday'].includes(e.operator.value))
                if(filteredOr.length > 0)
                    filteredArray.push(filteredOr)
            });

            let pool = [];
            if(!this.list) {
                pool = filteredArray;
            } else {
                pool = this._.cloneDeep(this.list.default_pool);
                pool.push(filteredArray);
            }

            let item = {
                "action": "actionMenus",
                "items": [
                    {
                        "action": "form",
                        "url": "\/export\/getForm?formName=config&params[source]=segment&params[source_id]=" + (this.segmentSegment) + "&params[target]=contact&params[filters]=" + encodeURIComponent(JSON.stringify(pool)),
                        "label": "theContactList",
                        "img": "contacts.png"
                    },
                    {
                        "action": "form",
                        "url": "\/export\/getForm?formName=config&params[source]=segment&params[source_id]=" + (this.segmentSegment) + "&params[target]=demand&params[filters]=" + encodeURIComponent(JSON.stringify(pool)),
                        "label": "theDemandList",
                        "img": "demands.png"
                    }
                ]
            };
            this.actionForm(item);
        },
        customRefresh(response){
            // if(response.data.data.directDownload){
            //     var link = document.createElement("a");
            //     link.setAttribute("href", this.api + response.data.data.directDownload);
            //     let id = 'export_'+Date.now();
            //     link.id = id;
            //     link.setAttribute("download", "");
            //     link.setAttribute("target", "_blank");
            //     document.body.appendChild(link); // Required for FF
            //     setTimeout(() => {
            //         document.getElementById(id).click(); // This will download the data file named "contacts.csv".
            //     }, 200);
                
            // }
        },
        actionForm(item) {
            if(this.showActionMenusDialog) {
                this.itemsActionMenusDialog = [];
                this.showActionMenusDialog = false;
            }

            if(item.action == "actionMenus") {
                this.itemsActionMenusDialog = item.items;
                this.showActionMenusDialog = true;
            } else {
                this.getForm(item.url)
            }
        },
    },
}
</script>
<style lang="scss">
        .btns {
            position:absolute;
            top:16px;
            right: 15px;
            display:flex;
            flex-direction: row;
            width:50%;
            justify-content: flex-end;
            z-index:1301
        }
        .segmentation {
            border-right:1px solid var(--v-mediumgrey-base) !important;
        }
        .or {
            position:relative;

        }
        .field {
            max-width:28%;
            min-width:28%;
        }
        .divider {
            position:absolute;
            top:0;
            width:100%;
            z-index:0;
            padding: 0 45px;
        }
        .operatorChip {
            border:1px solid var(--v-mediumgrey-base) !important;
            z-index:1;
            width:45px;
            display:flex;
            justify-content: center;
        }
        .blank {
            width:45px;
        }
        .removeBtn {
            min-width:45px !important;
        }
        .addButton {
            //margin-left:45px;
            display:flex;
            justify-content:space-between;
            align-items: center;
        }
        .andDiv {
            display:flex;
            justify-content:space-between;
            align-items: center;
            .removeBtn {
                opacity:0;
            }
            &.active {
                .removeBtn {
                    opacity:1;
                }
            }
        }
        .realTimeNumbers {
            display:flex;
            flex-direction: column;
            justify-content: center;
            align-items:center;
            padding:20px;
            .number {
                font-size:55px;
                line-height:55px;
                font-weight:bold;
            }
        }
        .headingDetail {
            display:flex;
            justify-content: space-between;
            align-items: center;
        }
    
</style>