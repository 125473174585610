<template>
    <v-dialog scrollable class="rounded-lg" persistent fullscreen v-model="displayUpload">
        <v-card >
            <v-toolbar tile flat dark  class="bg-gradient" height="64" style="height:80px;">
                <div style="display:flex; width:100%; height:64px; align-items:center; justify-content:space-between;">
                    <div class="d-flex align-center">
                        <v-toolbar-title class="d-flex align-center">{{$t('fileImportContactTitle')}}</v-toolbar-title>
                    </div>
                    <div>
                        <v-btn outlined dark depressed @click.native="cancelUpload">{{ $t('close') }}</v-btn>
                    </div>
                </div> 
            </v-toolbar>
            <v-card-text class="pa-0" v-if="importUrl">
                <iframe :src="importUrl" style="width:100%; height:100%; border:none;"></iframe>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import GenericDataService from '@/services/GenericDataService'
import vCustomSelect from '@/components/forms/inputs/v-custom-select'
import Loader from '@/components/Loader.vue'
export default {
    name: "importContactComponent",
    props: ["displayUpload", "operationId"],
    components: {vCustomSelect, Loader},
    data() {
        return {
            importUrl: '',
            entrypointId: null,
        }
    },
    watch: {
        displayUpload(val){
            if(val){
                window.addEventListener("message", this.iframeEvent, false);
                GenericDataService.getData('/operation/'+this.operationId+'/getImportData').then((response) => {
                    this.importUrl = response.data.data.importUrl
                    this.entrypointId = response.data.data.entrypoint_token
                });
            }
        }
    },
    mounted(){
        //Event Listener for Iframe
        //window.addEventListener("message", this.iframeEvent, false);
        // if(this.operationId){
        //     GenericDataService.getData('/operation/'+this.operationId+'/getImportData').then((response) => {
        //         this.importUrl = response.data.data.importUrl
        //     });
        // }
    },
    beforeDestroy () {
        window.removeEventListener('message', this.iframeEvent)
    },
    methods: {   
        iframeEvent(event){
            if(event.data === "Import Done"){
                GenericDataService.getData("/operation/"+this.operationId+"/registerImportForRp?entrypoint_id="+this.entrypointId).then((response) => {
                    this.$emit('closeModal')
                    this.$emit('refreshData')
                })
            }
            if(event.data === "Delete Done") {
                this.$emit('refreshData')
            }
        },
        cancelUpload(){
            this.$emit('closeModal', false)
        }
    },
}
</script>