<template>
    <div>
        <template v-if="(!limitRetro)">
            <v-menu v-if="$func.hasRight('import/add') && $func.hasRight('import/addData')" 
                        offset-y
                        center>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                    v-bind="attrs"
                    v-on="on"
                    depressed class="bg-gradient mb-4"
                    >
                    {{$t('ImportContactMenu')}}
                        <v-icon small class="ml-2">$caretdown_s</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item @click="displayUpload = true">
                        <v-list-item-title>{{$t('ImportContact')}}</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="displayUploadData = true">
                        <v-list-item-title>{{$t('ImportContactData')}}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
            <template v-else>
                <v-btn v-if="$func.hasRight('import/add')"  depressed class="bg-gradient mb-4" @click="displayUpload = true">
                    <v-icon small color="white" class="mr-3">$plus_l</v-icon>
                    {{$t('ImportContact')}}
                </v-btn>
                <v-btn v-if="$func.hasRight('import/addData')"  depressed class="bg-gradient mb-4" @click="displayUploadData = true">
                    <v-icon small color="white" class="mr-3">$plus_l</v-icon>
                    {{$t('ImportContactData')}}
                </v-btn>
            </template>
            <btnReaffectComponent :disable="disableReaffect" :operationId="operationId" :entities="entities"/>
        </template>

        <v-row v-if="!limitRetroId">
            <v-col v-for="retro in retroplanning" :key="retro.id" cols="6" class="colRetro">
                <retroplanningContactListComponent  :sousType="sousType" :loader="loaderRetro['retro_'+retro.id]" :retroplanning="retro" @addList="addList($event, retro)" @removeListFromLP="removeListFromLP($event, retro)" :key="'rp_'+retro.id+'_'+RPkey"/>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col v-for="retro in retroplanning" v-show="retro.id == limitRetroId" :key="retro.id" cols="12" class="colRetro">
                <v-btn v-if="(limitRetro && !['PROGRAMMED', 'COMPLETED'].includes(limitRetro.config.status)) && $func.hasRight('import/add')" small depressed class=" btnImportCamp bg-gradient mb-4" @click="displayUpload = true">
                    <v-icon small color="white" class="mr-3">$plus_l</v-icon>
                    {{$t('ImportContact')}}
                </v-btn>
                <retroplanningContactListComponent  :sousType="sousType" :loader="loaderRetro['retro_'+retro.id]" :limitRetro="limitRetro" :retroplanning="retro" @addList="addList($event, retro)" @removeListFromLP="removeListFromLP($event, retro)" :key="'rp_'+retro.id+'_'+RPkey"/>
            </v-col>
        </v-row>
        <importContactComponent @closeModal="closeModal" :displayUpload="displayUpload" :operationId="operationId" :returnData="true" />
        <importDataContactComponent @closeModal="closeModal" @refreshData="refreshDataContactsList" :displayUpload="displayUploadData" :operationId="operationId" :returnData="true" />
        <addListDialogComponent :operationId="operationId" :loader="loading" :displayAddList="displayAddList" :retroplanning="retroplanningToUpdate" :excludedIds="listIdToExclude" :entities="entities" @addToList="addToList" @close="closeAddListDialog" />
        <v-dialog v-model="dialogAddMulti" width="500">
            <v-card>
                <v-card-title>
                   <h3 class="black--text" style="font-size:20px;">{{$t('addListToAllRpTitle')}}</h3>
                </v-card-title>
                <v-card-text>
                    {{$t('addListToAllRpText')}}
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn depressed @click="addToAllRp(idToAddMulti)" :loading="loaderAddMulti" color="success">{{$t('yes')}}</v-btn>
                    <v-btn depressed @click="cancelAddAll" color="error">{{$t('no')}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import GenericDataService from '@/services/GenericDataService'
import FormDialogComponent from '@/components/dialogs/FormDialogComponent'
import getForm from "@/mixins/mixins";
import Loader from '@/components/Loader'
import dateManipulation from '@/mixins/dateManipulation'
import retroplanningContactListComponent from '@/modules/operations/operationsComponents/retroplanningContactListComponent'
import importContactComponent from '@/components/importContactComponent'
import importDataContactComponent from '@/components/importDataContactComponent.vue';
import addListDialogComponent from '@/modules/operations/operationsComponents/addListDialogComponent'
import btnReaffectComponent from '../../../components/btnReaffectComponent.vue';
export default {
    name: "contactListComponent",
    components:{FormDialogComponent, Loader, retroplanningContactListComponent, importContactComponent, importDataContactComponent, addListDialogComponent,btnReaffectComponent},
    mixins: [getForm, dateManipulation],
    props:['operationId', 'loadData', 'limitRetroId', 'sousType'],
    data() {
        return {
            retroplanning: false,
            loading: false,
            retroType: ['retroplanning.CAMP_OUT_P.campMAILINGout', 'retroplanning.CAMP_OUT_P.campSMSout', 'retroplanning.CAMP_OUT_P.campEMAILout', 'retroplanning.CAMP_OUT_P.campMMSout', 'retroplanning.CAMP_OUT_P.campVMSout', 'retroplanning.CAMP_CALL.campCALL'],
            displayUpload: false,
            displayUploadData: false,
            entityUrl : 'operation/' + this.operationId + '/getEntitySelectList2',
            entities : [],
            retroplanningToUpdate: {},
            listIdToExclude:[],
            displayAddList: false,
            dialogAddMulti: false,
            loaderAddMulti: false,
            idToAddMulti:0,
            RPkey:0,
            limitRetro: false,
            loaderRetro: {}
        }
    },
    watch: {
        loadData: {
            immediate:true,
            handler(val){
                if(val){
                    this.loading = true
                    GenericDataService.getData('/operation/'+this.operationId+'/getRetroplanning').then((response) => {
                        this.retroplanning = response.data.data.filter((e) => this.retroType.includes(e.type));
                        this.retroplanning.forEach(rp => {
                            this.loaderRetro['retro_'+rp.id] = false;
                        });
                        if(this.limitRetroId)
                            this.limitRetro = this.retroplanning.find(({ id }) => id == this.limitRetroId);
                        GenericDataService.getData('operation/' + this.operationId + '/getEntitySelectList2').then((response) => {
                            this.entities = response.data.data
                            this.loading = false
                        });
                    });
                }
            }
        }
    },
    computed: {
        disableReaffect() {
            let disable = true
            if(this.retroplanning){
                this.retroplanning.forEach(rp => {
                    if(rp.config.hasOwnProperty('contact_list') && rp.config.contact_list.length > 0){
                        disable = false
                    }
                });
            }
            return disable
            
        }
    },
    methods: {
        removeListFromLP(listId, retro) {
            this.loaderRetro['retro_'+retro.id] = true;
            this.RPkey++
            let newRetroplanning = this._.cloneDeep(retro)
            newRetroplanning.config.contact_list.splice(newRetroplanning.config.contact_list.findIndex((e) => e == listId), 1);
            GenericDataService.postData('operation/'+this.operationId+'/setConfigRetroPlanning', {campaign : newRetroplanning}).then((response) => {
                this.refreshDataContactsList()
                this.loaderRetro['retro_'+retro.id] = false;
            })            
        },
        refreshDataContactsList(){
            this.loading = true
            GenericDataService.getData('/operation/'+this.operationId+'/getRetroplanning').then((response) => {
                this.retroplanning = response.data.data.filter((e) => this.retroType.includes(e.type));
                this.retroplanning.forEach(rp => {
                    this.loaderRetro['retro_'+rp.id] = false;
                });
                GenericDataService.getData('operation/' + this.operationId + '/getEntitySelectList2').then((response) => {
                    this.entities = response.data.data
                    this.loading = false
                    this.$emit('refreshContactList');
                })
            })
        },
        addList(contactlist, retro) {
            this.retroplanningToUpdate = retro
            this.listIdToExclude = retro.config.hasOwnProperty('contact_list') ? retro.config.contact_list : []
            this.displayAddList = true
        },
        addToList($event, retroplanning){
            this.loading = true
            let newRetroplanning = this._.cloneDeep(retroplanning)
            if(newRetroplanning.config.hasOwnProperty('contact_list')){
                $event.forEach(id => {
                    newRetroplanning.config.contact_list.push(id)
                });
            } else {
                newRetroplanning.config.contact_list = $event
            }
            GenericDataService.postData('operation/'+this.operationId+'/setConfigRetroPlanning', {campaign : newRetroplanning}).then((response) => {
                this.refreshDataContactsList()
                this.retroplanningToUpdate = {}
                this.listIdToExclude = []
                this.loading = false
                this.displayAddList = false
                
            })
        },
        closeAddListDialog(){
            this.retroplanningToUpdate = {}
            this.listIdToExclude = []
            this.displayAddList = false
        },
        closeModal(reload = false) {
            if(reload){
                if(!this.limitRetroId) {
                    this.dialogAddMulti = true
                    this.idToAddMulti = reload
                } else {
                    let newRetro = this.retroplanning.find((e) => e.id == this.limitRetroId)
                    if(newRetro) {
                        this.addToList([reload], newRetro)
                    }
                }
            }
            this.displayUpload = false;
            this.displayUploadData = false;
        },
        addToAllRp(id){
            this.loaderAddMulti = true;
            let arrayCamp = this._.cloneDeep(this.retroplanning)
            arrayCamp.forEach((rp, index, array) => {
                if(rp.config.hasOwnProperty('contact_list')){
                    rp.config.contact_list.push(id)
                } else {
                    rp.config.contact_list = [id]
                }                
            });
            GenericDataService.postData('operation/'+this.operationId+'/setConfigToAllRetroplanning', {campaigns : arrayCamp}).then((response) => {
                this.refreshDataContactsList()
                this.RPkey++
                this.$forceUpdate()
                this.dialogAddMulti = false
                this.idToAddMulti = 0
                this.retroplanningToUpdate = {}
                this.listIdToExclude = [] 
                this.loaderAddMulti = false;
            })
        },
        cancelAddAll(){
            this.dialogAddMulti = false
            this.idToAddMulti = 0
        }
    },
}
</script>
<style lang="scss">
.colRetro {
    position:relative;
}
.btnImportCamp{
    position:absolute;
    z-index:5;
    top:28px;
    right:34px;
}
</style>